<template>
    <div>
        <el-form label-position="right" :rules="rules" ref='formInfo' inline label-width="80px" :model="formInfo"
            :size='tabsize'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="上级部门">
                        <el-input v-model="pName" @focus=" innerVisible = true " suffix-icon="el-icon-edit"
                            :disabled="typeof this.formInfo.id !== 'undefined' ">
                        </el-input>
                        <el-dialog width="30%" title="选择上级菜单" :visible.sync="innerVisible" append-to-body>
                            <el-form>
                                <el-tree :data="menu" node-key="id" ref="tree" highlight-current show-checkbox
                                    check-strictly :default-expanded-keys="defaultShowNodes" :props="defaultProps"
                                    :default-checked-keys='thispid' @check-change="handleNodeClick">
                                </el-tree>
                                <el-form-item class="btn">
                                    <el-button :size='tabsize' type="primary" @click="choosed">提交</el-button>
                                </el-form-item>
                            </el-form>
                        </el-dialog>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="部门名称" prop="cname">
                        <el-input v-model="formInfo.cname"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="部门地址">
                        <el-input v-model="formInfo.address"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="顺序" prop='inx'>
                        <el-input-number v-model="formInfo.inx" controls-position="right" :min="0" :max="99">
                        </el-input-number>
                        <!-- <el-input v-model="formInfo.inx" :min='0' :max='99'></el-input> -->
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span='12'>
                     <el-form-item label="部门状态" required>
                        <el-select v-model="formInfo.status" collapse-tags placeholder="请选择" @change='rolechange'>
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
            </el-col>
            <el-col :span='12'>
                <el-form-item label="负责人" required>
                    <el-input v-model="formInfo.inx"></el-input>
                </el-form-item>
            </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="联系电话" required>
                        <el-input v-model="formInfo.address"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="邮箱" required>
                        <el-input v-model="formInfo.inx"></el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->


            <p class="subbtn">
                <el-button type="primary" :size='tabsize' @click="submitForm('formInfo');">提交
                </el-button>
            </p>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                innerVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'cname'
                },
                pName: '',
                rules: {
                    cname: [{
                        required: true,
                        message: '请输入部门名称',
                        trigger: 'blur'
                    }],
                    inx: [{
                        required: true,
                        message: '请输入顺序',
                        trigger: 'blur'
                    }],
                },
                defaultShowNodes: [0],
                thispid: [],
                options: [{
                        value: 1,
                        label: '正常'
                    },
                    {
                        value: 0,
                        label: '停用'
                    }
                ],
            }
        },
        props: ['formInfo'],
        mounted() {
            this.thispid = []
            if (this.formInfo.id) {
                this.thispid.push(this.formInfo.pid)
            } else {
                this.thispid.push(0)
            }
            if (this.thispid == 0) {
                this.pName = '根节点'
            }
        },
        computed: {
            // 获取选择上级菜单树形控件数据
            menu() {
                let data = JSON.parse(localStorage.getItem('areaArr'))
                let arr = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].mtype !== 3) {
                        arr.push(data[i])
                    }
                }
                arr = this.$table2Tree(arr)

                return [{
                    pid: -1,
                    id: 0,
                    cname: '根节点',
                    children: arr
                }]
                // console.log(arr);
            },
            // 监听尺寸按钮变化 改变表格大小
            tabsize() {
                return this.$store.state.tabsize
            }
        },
        watch: {
            // 监听表单value变化
            formInfo: {
                immediate: true,
                handler() {
                    // if (!this.formInfo.pid) {
                    //     this.pName = ''
                    //     this.value = ''
                    // }
                    let data = JSON.parse(localStorage.getItem('areaArr'))
                    for (let i = 0; i < data.length; i++) {
                        if (this.formInfo.pid === data[i].id) {
                            this.pName = data[i].cname
                        }
                    }
                },
                deep: true
            },
            // pid() {
            //     this.innerVisible = true
            //     this.$nextTick(() => {
            //         this.$refs.tree.setCheckedKeys([pid])
            //         let node = this.$refs.tree.getCheckedNodes()[0].cname
            //         this.pName = node
            //         this.innerVisible = false
            //     });
            // }
        },
        methods: {
            // 提交表单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$parent.$parent.loading = true
                        this.$parent.$parent.formShow = false
                        let url
                        let str
                        if (this.formInfo.id) {
                            url = this.$api.dept.edit
                            str = '修改'
                        } else {
                            url = this.$api.dept.add
                            str = '添加'
                        }
                        url(this.formInfo)
                            .then(res => {
                                this.$parent.$parent.loading = false
                                if (res.status == 1) {
                                    this.$notify.success({
                                        title: 'Info',
                                        message: str + '成功',
                                        showClose: false,
                                        duration: 2000,
                                    });
                                    this.$parent.$parent.updateList()
                                } else {
                                    this.$notify.error({
                                        title: 'Info',
                                        message: res.msg,
                                        showClose: false,
                                        duration: 2000,
                                    });
                                }
                            }).catch((error) => {
                                console.log(error);
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            },
            // 确认选择上级菜单
            choosed() {
                this.pName = this.$refs.tree.getCheckedNodes()[0].cname
                this.formInfo.pid = this.$refs.tree.getCheckedKeys()[0]
                this.innerVisible = false
            },
            // 选择菜单类型
            typechange(value) {
                this.formInfo.mtype = value
            },
            // 上级菜单控件单选
            handleNodeClick(data, checked, node) {
                if (checked === true) {
                    this.checkedId = data.id;
                    this.$refs.tree.setCheckedKeys([data.id]);
                } else {
                    if (this.checkedId == data.id) {
                        this.$refs.tree.setCheckedKeys([data.id]);
                    }
                }
            },
            rolechange() {}
        },
    }
</script>

<style scoped>
    .el-input__inner,
    .el-form-item {
        width: 100%;
    }

    .el-form/deep/.el-form-item__content {
        width: calc(100% - 92px);
    }

    .subbtn {
        margin-top: 20px;
        text-align: center;
    }

    .el-tree {
        height: 400px;
        overflow-y: auto;
    }

    .el-dialog__body {
        position: relative;
    }


    .btn {
        /* position: absolute;
        bottom: 0; */
        margin-top: 30px;
        margin-bottom: 0 !important;
    }

    .el-input-number {
        width: 190px;
    }
</style>